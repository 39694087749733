// themecolor
$themecolor: #3e9ef0;

$primarybtn: #3e9ef0;

$white: #fff;

$mate-black: #212121;

$black: #474646;

$gradient-one: #3e9ef0;

$gradient-two: #3e9ef0;

$footer-back-color:#222222;

$header-top-color:#222222;

$breadcrumbs-color:#000000;
